<template>
  <v-card class="pa-8">
    <v-row no-gutters>
      <v-col>
        <ComponentTitleWithDataLoader
          :title="title"
        ></ComponentTitleWithDataLoader>
      </v-col>
    </v-row>
    <div v-if="!dataIsLoading">
      <v-data-table
        :headers="headers"
        :items="offers"
        :sort-by="['name']"
        :footer-props="{
          'items-per-page-text': $t('listing.job_offers.pagination')
        }"
        :item-class="rowClass"
        @click:row="clickRow"
      >
        <template v-slot:item.name="{ item, value }">
          {{ value }}
        </template>
        <template v-slot:item.available_from="{ value }">
          <span>{{ formatDate(value) }}</span>
        </template>
        <template v-slot:item.status_text="{ item, value }">
          {{ value }}
          <v-icon v-if="item.status[0] === 'ARCHIVED'" aria-hidden="true">
            mdi-archive
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { formatDate } from '@/helpers/dates'
import { mapGetters } from 'vuex'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'
import RouterMixin from '@/mixins/routerMixin'

export default {
  components: { ComponentTitleWithDataLoader },
  mixins: [RouterMixin],
  props: {
    offers: {
      type: Array,
      require: true,
      default: null
    }
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('listing.job_offers.headers.name'),
          value: 'name',
          align: 'start'
        },
        {
          text: this.$t('listing.job_offers.headers.start_date'),
          value: 'available_from'
        },
        {
          text: this.$t('listing.job_offers.headers.status'),
          value: 'status_text'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    title() {
      const count = this.offers.length
      const text = this.$t('listing.job_offers.registered')[(count > 1) ? 'plural' : 'singular']
      return `${count} ${text}`
    }
  },
  methods: {
    showOfferDetails(offerId) {
      this.routerPush(`/offers/${offerId}/details`)
    },
    formatDate,
    clickRow(item) {
      this.showOfferDetails(item.id)
    },
    rowClass() {
      return "clickable-row"
    }
  }
}
</script>
<style>
  .clickable-row {
    cursor: pointer;
  }
</style>
